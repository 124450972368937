import { HeadFC, PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  DesignLayout,
  ElementCard,
  Seo,
  VersionCard,
} from '../../../components';
import {
  Header2,
  SeparatorLineStyle,
  SubsectionText2,
} from '../../../components/design/cards/style';
import {
  BodyTextStyle,
  H1Style,
  H2Style,
  H3Style,
  H4Style,
  H5Style,
  ParagraphStyle,
  SubTitleStyle,
  TitleStyle,
} from '../../../components/design/typography/style';
import { FontWeiht } from '../../../models';
import { breakpoints } from '../../../theme';

const TypographyPage: FC<PageProps> = () => {
  return (
    <DesignLayout>
      <VersionCard title="Typography" />

      <ElementCard title="Font family">
        <ContainerStyle justifyContent="space-between">
          <div>
            <SubsectionText2>Family name</SubsectionText2>
            <Header2>Poppins</Header2>
          </div>
          <div>
            <DemoParagraphStyle>
              a b c d e f g h i j k l m n ñ o p q r s t u v w x y z
            </DemoParagraphStyle>
            <DemoParagraphStyle>
              A B C D E F G H I J K L M N Ñ O P Q R S T U V W X Y Z
            </DemoParagraphStyle>
            <DemoParagraphStyle>
              {'0 1 2 3 4 5 6 7 8 9 / * - + = # $ % & @ ( ) { } [ ] ¡ ! ¿ ?'}
            </DemoParagraphStyle>
          </div>
        </ContainerStyle>

        <SeparatorLineStyle />

        <SubsectionText2>Weights</SubsectionText2>
        <ContainerStyle>
          <DemoParagraphStyle>Regular</DemoParagraphStyle>
          <DemoParagraphStyle fontWeight="medium">Medium</DemoParagraphStyle>
          <DemoParagraphStyle fontWeight="semiBold">
            SemiBold
          </DemoParagraphStyle>
          <DemoParagraphStyle fontWeight="bold">Bold</DemoParagraphStyle>
        </ContainerStyle>
      </ElementCard>

      <ElementCard title="Sizes">
        <ContainerStyle>
          <H1Style fontWeight="regular">H1</H1Style>
          <H1Style fontWeight="medium">H1</H1Style>
          <H1Style fontWeight="semiBold">H1</H1Style>
          <H1Style fontWeight="bold">H1</H1Style>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <H2Style fontSize="h2" fontWeight="regular">
            H2
          </H2Style>
          <H2Style fontSize="h2" fontWeight="medium">
            H2
          </H2Style>
          <H2Style fontSize="h2" fontWeight="semiBold">
            H2
          </H2Style>
          <H2Style fontSize="h2" fontWeight="bold">
            H2
          </H2Style>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <H3Style fontSize="h3" fontWeight="regular">
            H3
          </H3Style>
          <H3Style fontSize="h3" fontWeight="medium">
            H3
          </H3Style>
          <H3Style fontSize="h3" fontWeight="semiBold">
            H3
          </H3Style>
          <H3Style fontSize="h3" fontWeight="bold">
            H3
          </H3Style>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <H4Style fontSize="h4" fontWeight="regular">
            H4
          </H4Style>
          <H4Style fontSize="h4" fontWeight="medium">
            H4
          </H4Style>
          <H4Style fontSize="h4" fontWeight="semiBold">
            H4
          </H4Style>
          <H4Style fontSize="h4" fontWeight="bold">
            H4
          </H4Style>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <H5Style fontSize="h5" fontWeight="regular">
            H5
          </H5Style>
          <H5Style fontSize="h5" fontWeight="medium">
            H5
          </H5Style>
          <H5Style fontSize="h5" fontWeight="semiBold">
            H5
          </H5Style>
          <H5Style fontSize="h5" fontWeight="bold">
            H5
          </H5Style>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <TitleStyle fontSize="title1" fontWeight="regular">
            Title 1
          </TitleStyle>
          <TitleStyle fontSize="title1" fontWeight="medium">
            Title 1
          </TitleStyle>
          <TitleStyle fontSize="title1" fontWeight="semiBold">
            Title 1
          </TitleStyle>
          <TitleStyle fontSize="title1" fontWeight="bold">
            Title 1
          </TitleStyle>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <TitleStyle fontSize="title2" fontWeight="regular">
            Title 2
          </TitleStyle>
          <TitleStyle fontSize="title2" fontWeight="medium">
            Title 2
          </TitleStyle>
          <TitleStyle fontSize="title2" fontWeight="semiBold">
            Title 2
          </TitleStyle>
          <TitleStyle fontSize="title2" fontWeight="bold">
            Title 2
          </TitleStyle>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <SubTitleStyle fontSize="subTitle1" fontWeight="regular">
            Subtitle 1
          </SubTitleStyle>
          <SubTitleStyle fontSize="subTitle1" fontWeight="medium">
            Subtitle 1
          </SubTitleStyle>
          <SubTitleStyle fontSize="subTitle1" fontWeight="semiBold">
            Subtitle 1
          </SubTitleStyle>
          <SubTitleStyle fontSize="subTitle1" fontWeight="bold">
            Subtitle 1
          </SubTitleStyle>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <SubTitleStyle fontSize="subTitle2" fontWeight="regular">
            Subtitle 2
          </SubTitleStyle>
          <SubTitleStyle fontSize="subTitle2" fontWeight="medium">
            Subtitle 2
          </SubTitleStyle>
          <SubTitleStyle fontSize="subTitle2" fontWeight="semiBold">
            Subtitle 2
          </SubTitleStyle>
          <SubTitleStyle fontSize="subTitle2" fontWeight="bold">
            Subtitle 2
          </SubTitleStyle>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <BodyTextStyle fontSize="bodyText1" fontWeight="regular">
            Bodytext 1
          </BodyTextStyle>
          <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
            Bodytext 1
          </BodyTextStyle>
          <BodyTextStyle fontSize="bodyText1" fontWeight="semiBold">
            Bodytext 1
          </BodyTextStyle>
          <BodyTextStyle fontSize="bodyText1" fontWeight="bold">
            Bodytext 1
          </BodyTextStyle>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <BodyTextStyle fontSize="bodyText2" fontWeight="regular">
            Bodytext 2
          </BodyTextStyle>
          <BodyTextStyle fontSize="bodyText2" fontWeight="medium">
            Bodytext 2
          </BodyTextStyle>
          <BodyTextStyle fontSize="bodyText2" fontWeight="semiBold">
            Bodytext 2
          </BodyTextStyle>
          <BodyTextStyle fontSize="bodyText2" fontWeight="bold">
            Bodytext 2
          </BodyTextStyle>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <ParagraphStyle fontSize="paragraph1" fontWeight="regular">
            Paragraph 1
          </ParagraphStyle>
          <ParagraphStyle fontSize="paragraph1" fontWeight="medium">
            Paragraph 1
          </ParagraphStyle>
          <ParagraphStyle fontSize="paragraph1" fontWeight="semiBold" upperCase>
            Paragraph 1 uppercase
          </ParagraphStyle>
          <ParagraphStyle fontSize="paragraph1" fontWeight="bold" upperCase>
            Paragraph 1 uppercase
          </ParagraphStyle>
        </ContainerStyle>

        <SeparatorLineStyle />

        <ContainerStyle>
          <ParagraphStyle fontSize="paragraph2" fontWeight="regular">
            Paragraph 2
          </ParagraphStyle>
          <ParagraphStyle fontSize="paragraph2" fontWeight="medium">
            Paragraph 2
          </ParagraphStyle>
          <ParagraphStyle fontSize="paragraph2" fontWeight="semiBold" upperCase>
            Paragraph 2 uppercase
          </ParagraphStyle>
          <ParagraphStyle fontSize="paragraph2" fontWeight="bold" upperCase>
            Paragraph 2 uppercase
          </ParagraphStyle>
        </ContainerStyle>
      </ElementCard>
    </DesignLayout>
  );
};

export default TypographyPage;
export const Head: HeadFC = () => <Seo title="Theme" />;

const ContainerStyle = styled.div<{
  justifyContent?: 'flex-start' | 'space-between';
}>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};

  @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;
    gap: 3rem;
  }
`;

const DemoParagraphStyle = styled.p<{
  fontWeight?: keyof FontWeiht;
}>`
  font-size: 1.5625em;
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight
      ? theme.typography.fontWeight[fontWeight]
      : theme.typography.fontWeight.regular};
  text-align: right;
`;
